import { nativeCall } from './nativeCall';

export const moveBackInfo = (
  /** 파라미터를 제외한 전체 URL */
  url: string,
  /** 파라미터 정보 Object */
  searchParams: { [key: string]: string },
  /** 스택 사용 여부 */
  useYN: 'Y' | 'N' = 'Y',
) => {
  nativeCall(
    'addHistoryStack',
    'setTopMenuV2',
    '',
    JSON.stringify({
      url,
      params: searchParams,
      useYN, //스택사용 여부
    }),
  );
};
