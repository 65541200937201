import { cn } from '@common/utils';

import styles from './Logo.module.scss';
import Biz from './svg/biz.svg';
import Wello from './svg/wello.svg';

export interface LogoProps
  extends Omit<React.SVGProps<SVGSVGElement>, 'origin'> {
  name?: 'wello' | 'biz';
  className?: string;
  origin?: boolean;
}

export const Logo = ({
  name = 'wello',
  className,
  origin = false,
  ...restProps
}: LogoProps) => {
  const Svg = {
    wello: Wello,
    biz: Biz,
  }[name];

  return (
    <Svg
      {...restProps}
      className={cn(
        {
          [styles.logo]: !origin,
        },
        'text-[#304FFE]',
        className,
      )}
      name={name}
    />
  );
};
