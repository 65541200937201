import { useRef } from 'react';
import { useCookies } from 'react-cookie';

import { decodeJwt } from 'jose';

import { AuthOpenApi } from '@/api';
import { COOKIES_KEYS, COOKIE_OPTIONS } from '@/constants';

type Token = string | null;

export const useAccessToken = () => {
  const [cookies, setCookies, removeCookies] = useCookies([
    COOKIES_KEYS.ACCESS_TOKEN,
    COOKIES_KEYS.REFRESH_TOKEN,
  ]);

  const { current: setter } = useRef({
    setAccessToken: (accessToken: Token) => {
      const removeToken = () => {
        AuthOpenApi.TOKEN = undefined;
        removeCookies(COOKIES_KEYS.ACCESS_TOKEN, COOKIE_OPTIONS);
      };

      try {
        if (!accessToken) return removeToken();

        const decoded = decodeJwt(accessToken);

        if (!decoded.exp) return removeToken();

        const expire = decoded.exp * 1_000;

        if (expire < Date.now()) return removeToken();

        AuthOpenApi.TOKEN = accessToken;

        return setCookies(COOKIES_KEYS.ACCESS_TOKEN, accessToken, {
          ...COOKIE_OPTIONS,
          expires: new Date(expire),
        });
      } catch (e) {
        console.error(e);

        return removeToken();
      }
    },
    setRefreshToken: (refreshToken: Token) => {
      const removeToken = () =>
        removeCookies(COOKIES_KEYS.REFRESH_TOKEN, COOKIE_OPTIONS);

      try {
        if (!refreshToken) return removeToken();

        const decoded = decodeJwt(refreshToken);

        if (!decoded.exp) return removeToken();

        const expire = decoded.exp * 1_000;

        if (expire < Date.now()) return removeToken();

        return setCookies(COOKIES_KEYS.REFRESH_TOKEN, refreshToken, {
          ...COOKIE_OPTIONS,
          expires: new Date(expire),
        });
      } catch (e) {
        return removeToken();
      }
    },
  });

  const accessToken: Token = cookies.accessToken || null;
  const refreshToken: Token = cookies.refreshToken || null;

  return {
    accessToken,
    refreshToken,
    ...setter,
  };
};
