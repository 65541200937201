import {
  type BadgeProps as RadixBadgeProps,
  Badge as RadixBadge,
} from '@radix-ui/themes';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@common/utils';

import ApplyBadge from './svg/apply-badge.svg';
import BuildingBadge from './svg/building-badge.svg';
import StarBadge from './svg/star-badge.svg';

const badgeVariants = cva([
  'rounded-xsmall',
  'px-6 py-2',
  'typo-caption1 font-600',
  'flex gap-4 items-center',
  'bg-Gray-200 text-Gray-700',
]);

interface BadgeProps
  extends Omit<RadixBadgeProps, 'color' | 'disabled'>,
    VariantProps<typeof badgeVariants> {
  icon?: 'building' | 'star' | 'apply';
}

const Badge = ({ className, icon, ...props }: BadgeProps) => {
  const Icon =
    icon === 'building' ? BuildingBadge : icon === 'star' ? StarBadge : null;

  if (icon === 'apply') {
    return <ApplyBadge />;
  }

  return (
    <RadixBadge className={cn(badgeVariants(), className)} {...props}>
      {Icon ? <Icon /> : null}
      {props.children}
    </RadixBadge>
  );
};

export { Badge, type BadgeProps };
