import { Tooltip } from '@common/components/organism/Tooltip/Tooltip';
import { cn } from '@common/utils';

export const CertificationBadge = ({
  isDark = false,
  size = 40,
}: {
  isDark?: boolean;
  size?: 40 | 60 | 80;
}) => {
  return (
    <ul className="flex gap-24 items-center">
      <li>
        <Tooltip
          className={cn({ 'bg-Gray-800': isDark })}
          delayDuration={0}
          label={
            <p>
              [인증범위]
              <br />
              웰로 서비스 및 웰로비즈 서비스
              <br />
              [유효기간]
              <br />
              2024-11-20 ~ 2027-11-19
            </p>
          }
        >
          <img
            alt="ISMS-P 로고"
            className={cn({
              'size-40': size === 40,
              'size-60': size === 60,
              'size-80': size === 80,
            })}
            src={
              isDark
                ? 'https://cdn.wellobiz.com/certificate/ISMS-P.png'
                : 'https://cdn.welfarehello.com/certificate/ISMS-P.png'
            }
            title="ISMS-P"
          />
        </Tooltip>
      </li>
      <li>
        <Tooltip
          className={cn({ 'bg-Gray-800': isDark })}
          delayDuration={0}
          label={
            <p>
              [인증범위]
              <br />
              정부지원 정책 정보 제공 및 관리 서비스를 위한
              정보보호경영시스템(B2B 및 B2C)
              <br />
              [유효기간]
              <br />
              2024-12-02 ~ 2027-12-01
            </p>
          }
        >
          <img
            alt="ISO27001 로고"
            className={cn({
              'w-23': size === 40,
              'w-34': size === 60,
              'w-46': size === 80,
            })}
            src={
              isDark
                ? 'https://cdn.wellobiz.com/certificate/ISO-27001.png'
                : 'https://cdn.welfarehello.com/certificate/ISO-27001.png'
            }
            title="ISO27001"
          />
        </Tooltip>
      </li>
    </ul>
  );
};
