'use client';

import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';

import { AUTH_API } from '@/api';
import { useAccessToken } from '@/hooks/useAccessToken';
import { useLoginUrlData } from '@/hooks/useLoginUrlData';
import { useAuthStore } from '@/stores/AuthStore';
import { WEBVIEW_TYPE, useDeviceInfoStore } from '@/stores/DeviceStore';

import {
  KB_PAY_CUSTOM_SCHEME,
  KB_PAY_CUSTOM_SEARCH_PARAMS,
} from '../_utils/reserved-keywords';

export const KbAppController = () => {
  const pathname = usePathname();
  const isKbWebview = useDeviceInfoStore(
    (state) => state.webviewType === WEBVIEW_TYPE.KB_PAY,
  );

  const { loginParams } = useLoginUrlData();

  const kbId = new URLSearchParams(loginParams).get(
    KB_PAY_CUSTOM_SEARCH_PARAMS.KB_ID,
  );

  useEffect(() => {
    if (!isKbWebview) return;

    document.querySelectorAll('a').forEach((el) => {
      const href = el.getAttribute('href');

      if (!href) return;

      if (!href.startsWith('https')) return;

      if (href.startsWith(location.origin)) return;

      el.addEventListener('click', (e) => {
        e.preventDefault();

        window.location.href = `${KB_PAY_CUSTOM_SCHEME.EXTERNAL_PREFIX}${href}`;
      });
    });
  }, [isKbWebview, pathname]);

  const isLogin = useAuthStore((state) => state.isLogin);

  const { accessToken, refreshToken } = useAccessToken();

  const isEnabledKbPayMemberCheck = !!(
    isLogin &&
    accessToken &&
    refreshToken &&
    kbId
  );

  useQuery({
    enabled: isEnabledKbPayMemberCheck,
    queryKey: [
      WEBVIEW_TYPE.KB_PAY,
      kbId,
      accessToken,
      refreshToken,
      isEnabledKbPayMemberCheck,
    ],
    queryFn: () => {
      if (!isEnabledKbPayMemberCheck) return;

      AUTH_API.checkKbPayMember({
        requestBody: {
          access_token: accessToken,
          refresh_token: refreshToken,
          external_member_id: kbId,
        },
      });
    },
  });

  return <></>;
};
