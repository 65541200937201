import type { TERM_CATEGORY } from '@/api';

import { create } from 'zustand';

export enum LOCAL_STORAGE_KEY {
  RECENT_SEARCH_LIST = 'recent_search_list',
  SEARCH_LOCATION_LIST = 'search_location_list',
  BANNER_CLOSED = 'bannerClosed',
  WELLO_UUID = 'welloUuid',
  CARD_BANNER_CLOSED = 'cardBannerClosed',
}

export type ToastType = 'fail' | 'success' | 'warning' | 'info';

export interface ToastOptions {
  toastKey: string;
  type?: ToastType;
  message: string;
  holdTime?: number;
  createdAt: Date;
}

interface WelloWebLinkModalPros {
  descriptions: string;
  link: string;
}

interface Log {
  data: string;
  time: Date;
}
interface GlobalStore {
  isGlobalLoading: boolean;
  setIsGlobalLoading: (isGlobalLoading: boolean) => void;

  termsDrawerType: TERM_CATEGORY | null;
  setTermsDrawerType: (termsDrawerType: TERM_CATEGORY | null) => void;

  welloWebLinkModalProps: WelloWebLinkModalPros | null;
  setWelloWebLinkModalProps: (
    welloWebLinkModal: WelloWebLinkModalPros | null,
  ) => void;

  shareLink: null | string;
  setShareLink: (shareLink: string | null) => void;

  isShowLoginRedirectionConfirmModal: boolean;
  setIsShowLoginRedirectionConfirmModal: (
    isShowLoginDirectionConformModal: boolean,
  ) => void;

  isNaverMapMounted: boolean;
  setIsNaverMapMounted: (isNaverMapMounted: boolean) => void;

  logList: Log[];
  addLog: (log: Log) => void;

  setGlobalNavigation: (globalNavigation: React.ReactNode) => void;
  globalNavigation: React.ReactNode;

  handleLocationServiceConsentDrawer?: (
    isLocationServiceConsent: boolean,
  ) => Promise<void>;
  setHandleLocationServiceConsentDrawer: (
    handleLocationServiceConsentDrawer: GlobalStore['handleLocationServiceConsentDrawer'],
  ) => void;

  isHomeBottomDrawerOpened: boolean;
  setIsHomeBottomDrawerOpened: (isHomeBottomDrawerOpened: boolean) => void;
}

export const useGlobalStore = create<GlobalStore>((set) => {
  return {
    isGlobalLoading: false,
    setIsGlobalLoading: (isGlobalLoading) => set({ isGlobalLoading }),

    termsDrawerType: null,
    setTermsDrawerType: (termsDrawerType) => set({ termsDrawerType }),

    welloWebLinkModalProps: null,
    setWelloWebLinkModalProps: (welloWebLinkModalProps) =>
      set({ welloWebLinkModalProps }),

    shareLink: null,
    setShareLink: (shareLink) => set({ shareLink }),

    isShowLoginRedirectionConfirmModal: false,
    setIsShowLoginRedirectionConfirmModal: (
      isShowLoginRedirectionConfirmModal,
    ) => set({ isShowLoginRedirectionConfirmModal }),

    isNaverMapMounted: false,
    setIsNaverMapMounted: (isNaverMapMounted) => set({ isNaverMapMounted }),

    logList: [],
    addLog: (log) => set(({ logList }) => ({ logList: [...logList, log] })),

    setGlobalNavigation: (globalNavigation) => set({ globalNavigation }),
    globalNavigation: null,

    // Drawe 띄우고 콜백 역할함
    setHandleLocationServiceConsentDrawer: (
      handleLocationServiceConsentDrawer,
    ) => set({ handleLocationServiceConsentDrawer }),

    isHomeBottomDrawerOpened: false,
    setIsHomeBottomDrawerOpened: (isHomeBottomDrawerOpened) =>
      set({ isHomeBottomDrawerOpened }),
  };
});
