'use client';

import { useEffect } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';
import { create } from 'zustand';

import { ENV, ROUTES, SEARCH_PARAMS } from '@/constants';
import { useAuthStore } from '@/stores/AuthStore';
import { findRoute } from '@/utils';

import { moveBackInfo } from '../_utils/moveBackInfo';
import { isKbWebview } from '../_utils/nativeCall';
import { setTopMenuV2 } from '../_utils/setTopMenuV2';

export const KB_DUMMY_PATHNAME = 'kbpostparam';
export const KB_DUMMY_PATHNAME_SPLIT_POINT = '__';

interface KbHistoryStore {
  historyList: string[];
  setHistoryList: (setter: (history: string[]) => string[]) => void;
}

export const kbHistoryStore = create<KbHistoryStore>((set) => ({
  historyList: [],
  setHistoryList: (setter) =>
    set((state) => ({ historyList: setter(state.historyList) })),
}));

export const KbHistoryStack = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const isLogin = useAuthStore((state) => state.isLogin);

  useEffect(() => {
    if (!pathname) return;

    if (!isKbWebview()) {
      const isAllowedPath = [
        '/login',
        '/join',
        '/terms',
        '/easy-certification',
        '/filter',
      ].some((path) => pathname.startsWith(path));

      if (!isAllowedPath) {
        const originalWello = ENV.NEXT_PUBLIC_DOMAIN;

        location.href = location.href.replace(location.origin, originalWello);
      }
    }
  }, [pathname]);

  useEffect(() => {
    setTopMenuV2('', '', {
      menuBt: 'Y',
      preBt: 'N',
      close: 'N',
      title: '맞춤형 정책지원금 알림',
      preAction: 'B',
      target: '',
    });
  }, []);

  const currentRoute = findRoute(pathname);

  const isExceptRoute = currentRoute
    ? [
        ROUTES.FAMILY_PROFILE_UPDATE,
        ROUTES.AUTH,
        ROUTES.CROSS_DOMAIN_AUTH,
        ROUTES.CROSS_DOMAIN_CALLBACK,
        ROUTES.SEARCH,
        ROUTES.ERROR,
      ]
        .map(({ id }) => id)
        .includes(currentRoute.id)
    : true;

  useEffect(() => {
    if (
      !(pathname && searchParams) ||
      isExceptRoute ||
      !isLogin ||
      !isKbWebview()
    )
      return;

    let kbDummyPathname = pathname;
    const params: { [key: string]: string } = {};

    searchParams.forEach((value, key) => {
      if (key === SEARCH_PARAMS.POST_REDIRECT) return;
      const lastChar = kbDummyPathname.slice(-1);

      //! ⚠️ KB 쪽에서 SearchParams가 달라도 Pathname이 같으면 같은 페이지로 인식하기 때문에 Pathname에 파라미터를 붙여주는 로직 미들웨어에서 벗겨내는 로직으로 페이지 이동
      kbDummyPathname =
        kbDummyPathname +
        `${lastChar === '/' ? '' : '/'}${KB_DUMMY_PATHNAME}${KB_DUMMY_PATHNAME_SPLIT_POINT}${key}${KB_DUMMY_PATHNAME_SPLIT_POINT}${value}`;

      params[key] = value;
    });

    const url = new URL(kbDummyPathname, location.origin).toString();

    const { historyList, setHistoryList } = kbHistoryStore.getState();

    const lastHistory = historyList[historyList.length - 1];

    const isNewHistory = lastHistory !== url;

    if (!isNewHistory) return;

    moveBackInfo(url, params, 'Y');

    setHistoryList((history) => [...history, url]);
  }, [isExceptRoute, isLogin, pathname, searchParams]);

  return <></>;
};
