import { useCookies } from 'react-cookie';

import { COOKIES_KEYS, COOKIE_OPTIONS } from '@/constants';

export const useLoginUrlData = () => {
  const [cookies, setCookies] = useCookies([
    COOKIES_KEYS.ORIGIN_HOST,
    COOKIES_KEYS.LOGIN_PARAMS,
  ]);

  const hostOrigin: string | undefined = cookies.hostOrigin;
  const loginParams: string | undefined = cookies.loginParams;

  const saveLoginUrlData = () => {
    const url = new URL(location.href);

    setCookies(COOKIES_KEYS.ORIGIN_HOST, url.origin, COOKIE_OPTIONS);
    setCookies(
      COOKIES_KEYS.LOGIN_PARAMS,
      url.searchParams.toString(),
      COOKIE_OPTIONS,
    );
  };

  return {
    redirect: new URLSearchParams(loginParams).get('redirect'),
    hostOrigin,
    loginParams,
    saveLoginUrlData,
  };
};
