'use client';

import { useMemo } from 'react';

import { isCurrentUrl } from '@common/utils';
import { useRouter } from 'next/navigation';

import { ROUTES, STORAGE_KEY } from '@/constants';
import { CUSTOM_SCHEME } from '@/modules/wello-app';
import { useGlobalStore } from '@/stores/GlobalStore';
import { useHistoryStore } from '@/stores/HistoryStore/useHistoryStore';

import { useCheckWelloApp } from './useCheckWelloApp';

const NOT_ALLOW_BACK_ROUTE = [
  ROUTES.AUTH,
  ROUTES.EASY_CERTIFICATION,
  ROUTES.EASY_CERTIFICATION_CALLBACK,
  ROUTES.LOGIN,
  ROUTES.CROSS_DOMAIN_AUTH,
];

export const useCustomRouter = () => {
  const router = useRouter();
  const setIsGlobalLoading = useGlobalStore(
    (state) => state.setIsGlobalLoading,
  );

  const { isWelloApp } = useCheckWelloApp();

  const customRouter = useMemo(() => {
    interface Options
      extends Exclude<Parameters<typeof router.push>[1], undefined> {
      globalLoading?: boolean;
    }

    const moveInAppEasyCertification = (href: string) => {
      if (isWelloApp && href.includes(ROUTES.EASY_CERTIFICATION.pathname)) {
        const url = new URL(href, location.origin);

        location.href = `${CUSTOM_SCHEME.IN_APP_EXTERNAL_PREFIX}${url.href}`;

        return true;
      }
    };

    const push = (href: string, options?: Options) => {
      const { globalLoading, ...originalOptions } = options ?? {};
      if (!isCurrentUrl(href, { skipSearch: true }))
        setIsGlobalLoading(globalLoading ?? true);

      if (moveInAppEasyCertification(href)) return;

      return router.push(href, originalOptions);
    };

    const replace = (href: string, options?: Options) => {
      const { globalLoading, ...originalOptions } = options ?? {};
      if (!isCurrentUrl(href, { skipSearch: true }))
        setIsGlobalLoading(globalLoading ?? true);

      if (moveInAppEasyCertification(href)) return;

      sessionStorage.setItem(STORAGE_KEY.REPLACE_MARK, 'true');

      return router.replace(href, originalOptions);
    };

    const back = () => {
      const { historyIndex, customHistory } = useHistoryStore.getState();

      const isEmptyHistory = historyIndex < 1;
      const prevHistoryPath = customHistory.get(historyIndex - 1);
      const isNotAllowBackRoute = prevHistoryPath
        ? NOT_ALLOW_BACK_ROUTE.some((route) =>
            prevHistoryPath.includes(route.pathname),
          )
        : true;

      if (isEmptyHistory || isNotAllowBackRoute)
        return replace(ROUTES.HOME.pathname);

      return router.back();
    };

    return {
      ...router,
      push,
      replace,
      back,
    };
  }, [isWelloApp, router, setIsGlobalLoading]);

  return customRouter;
};
