import type { DeviceTokenData } from '@/stores/DeviceStore';

import { useEffect, useState } from 'react';

import { BottomDrawer, Button } from '@common/components';
import { useShallow } from 'zustand/react/shallow';

import Camera from '@/assets/svgs/permission/camera.svg';
import ImgPicker from '@/assets/svgs/permission/img-picker.svg';
import Location from '@/assets/svgs/permission/location.svg';
import Notification from '@/assets/svgs/permission/notification.svg';
import { useDeviceInfoStore } from '@/stores/DeviceStore';

import { MESSAGE_KEYWORD, useHandleApp } from '..';

import styles from './AppModal.module.scss';

export const AppModal = () => {
  const [appModal, setAppModal, setTempDeviceTokenData] = useDeviceInfoStore(
    useShallow((state) => [
      state.appModal,
      state.setAppModal,
      state.setTempDeviceTokenData,
    ]),
  );

  const { isOpened, type } = appModal;

  const { request: requestExit } = useHandleApp({
    type: MESSAGE_KEYWORD.EXIT_APP,
  });

  const { request: requestRestart } = useHandleApp({
    type: MESSAGE_KEYWORD.RESTART_APP,
  });

  const closeModal = () =>
    setAppModal({
      type,
      isOpened: false,
    });

  const { request: requestCheckPermission } = useHandleApp({
    type: MESSAGE_KEYWORD.CHECK_PERMISSION,
  });

  const { request: requestNotification, response: notificationResponse } =
    useHandleApp<DeviceTokenData>({
      type: MESSAGE_KEYWORD.NOTIFICATION,
    });

  const [isSwiftPending, setIsSwiftPending] = useState(false);

  useEffect(() => {
    if (!isSwiftPending) return;

    if (navigator.userAgent.includes('swift')) {
      setTimeout(requestNotification, 5_000);
    }
  }, [isSwiftPending, requestNotification]);

  useEffect(() => {
    const { data, isSuccess } = notificationResponse ?? {};

    if (isSuccess && data) {
      if (navigator.userAgent.includes('swift')) {
        if (data.fcm_token) {
          setTempDeviceTokenData(data);
          setIsSwiftPending(false);
        } else {
          setIsSwiftPending(true);
        }
      } else {
        setTempDeviceTokenData(data);
      }

      requestCheckPermission({
        permissionType: MESSAGE_KEYWORD.LOCATION,
      });
    }
  }, [notificationResponse, requestCheckPermission, setTempDeviceTokenData]);

  return (
    <BottomDrawer
      opened={isOpened}
      onClose={() => {
        //! ⚠️ 권한 신청 창은 확인 버튼 클릭 시에만 닫힘
        if (type !== 'permission') {
          closeModal();
        }
      }}
    >
      <BottomDrawer.HandleWrapper>
        <BottomDrawer.Handle />
      </BottomDrawer.HandleWrapper>
      <BottomDrawer.Contents>
        {
          {
            permission: (
              <article className={styles['permission-drawer']}>
                <h2>
                  서비스 이용을 위한
                  <br />
                  권한 접근이 필요해요.
                </h2>
                <ul className={styles['permission-list']}>
                  {[
                    {
                      icon: Notification,
                      required: false,
                      title: '알림',
                      description: '개인별 맞춤 정보 알림',
                    },
                    {
                      icon: Location,
                      required: false,
                      title: '위치',
                      description: '검색 및 동네소식에서 현재 위치 사용',
                    },
                    {
                      icon: Camera,
                      required: false,
                      title: '카메라',
                      description: '프로필 이미지 등록',
                    },
                    {
                      icon: ImgPicker,
                      required: false,
                      title: '사진',
                      description: '프로필 이미지 등록',
                    },
                  ].map(
                    ({ icon: Icon, required, title, description }, index) => (
                      <li key={index}>
                        <div className={styles['permission-icon-wrap']}>
                          <Icon alt={title} />
                        </div>
                        <div className={styles['permission-info-container']}>
                          <h3>
                            {title}
                            {required || <span>(선택)</span>}
                          </h3>
                          <p>{description}</p>
                        </div>
                      </li>
                    ),
                  )}
                </ul>
                <footer>
                  <p>{`* 이후 기기 내 ‘설정 > 웰로’에서 권한 수정이 가능합니다.`}</p>
                  <Button
                    roundness="capsule"
                    onClick={async () => {
                      requestNotification();

                      closeModal();
                    }}
                  >
                    확인
                  </Button>
                </footer>
              </article>
            ),
            exit: (
              <article className={styles['exit-drawer']}>
                <h2>앱을 나가시겠어요?</h2>
                <p>더이상 뒤로 갈 페이지가 없어요.</p>
                <Button roundness="capsule" onClick={closeModal}>
                  계속 볼게요
                </Button>
                <Button theme="sub-text" onClick={() => requestExit()}>
                  나갈게요
                </Button>
              </article>
            ),
            restart: (
              <article className={styles['exit-drawer']}>
                <h2>앱이 업데이트 되었어요!</h2>
                <p>
                  사용자들의 의견을 반영한 업데이트가 있어요.
                  <br />
                  업데이트 하고 신규 기능을 이용해 보세요.
                </p>
                <Button roundness="capsule" onClick={() => requestRestart()}>
                  업데이트
                </Button>
                <Button theme="sub-text" onClick={closeModal}>
                  다음에 할래요
                </Button>
              </article>
            ),
          }[type]
        }
      </BottomDrawer.Contents>
    </BottomDrawer>
  );
};
