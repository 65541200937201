'use client';

import { useLayoutEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { MESSAGE_KEYWORD, useHandleApp } from '@/modules/wello-app';
import { STATUS_COLOR, useDeviceInfoStore } from '@/stores/DeviceStore';

interface UseStatusBarColorProps {
  color: string;
}

export const useStatusBarColor = ({ color }: UseStatusBarColorProps) => {
  const { request: requestStatusColor } = useHandleApp({
    type: MESSAGE_KEYWORD.STATUS_COLOR,
  });

  const [appVersion, setAppStatusColor] = useDeviceInfoStore(
    useShallow((state) => [state.appVersion, state.setAppStatusColor]),
  );

  useLayoutEffect(() => {
    if (!appVersion) return;

    const changeStatusColor = (color: string) => {
      requestStatusColor({
        color,
      });
      setAppStatusColor(color);
    };

    changeStatusColor(color);

    return () => changeStatusColor(STATUS_COLOR.DEFAULT);
  }, [appVersion, color, requestStatusColor, setAppStatusColor]);
};
