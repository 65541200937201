import { isKbWebview, nativeCall } from './nativeCall';

interface Jparam {
  menuBt: 'N' | 'Y';
  /** 상단바 왼쪽 이전 버튼 노출여부  */
  preBt: 'N' | 'Y';
  /** 상단바 오른쪽 닫기버튼 노출여부 */
  close: 'N' | 'Y';
  /** 상단바 중간 타이틀 설정 */
  title: string;
  /** 이전버튼 액션 - B(히스토리백), C(Close),T(Target 페이지 이동),F(Target 함수 호출) */
  preAction: 'B' | 'C' | 'T' | 'F';
  /** preAction T,F인 경우(url 혹은 fuction) */
  target: string;
}

export const setTopMenuV2 = (
  succFnNm: string,
  failFnNm: string,
  jparam: Jparam = {
    menuBt: 'Y',
    preBt: 'Y',
    close: 'N',
    //TODO: 메타데이터 정의 완료 후 연동
    title: '맞춤형 정책지원금 알림',
    preAction: 'B',
    target: '',
  },
) => {
  nativeCall('setTopMenuV2', succFnNm, failFnNm, JSON.stringify(jparam));
};

if (typeof window !== 'undefined' && isKbWebview()) {
  (
    window as typeof window & { setTopMenuV2: typeof setTopMenuV2 }
  ).setTopMenuV2 = setTopMenuV2;
}
