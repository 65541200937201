interface SpinnerProps {
  color?: 'black' | 'white';
  size?: number;
  logo?: boolean;
}

export const Spinner = ({
  color = 'black',
  size = 40,
  logo = false,
}: SpinnerProps) => {
  return (
    <div
      className="relative flex items-center justify-center opacity-100"
      style={{ width: size, height: size }}
    >
      <svg
        className="animate-spin"
        color={color}
        fill="none"
        height={size}
        style={{ animationDuration: '0.5s' }}
        viewBox="0 0 40 40"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="spinner-secondHalf">
            <stop offset="0%" stopColor="currentColor" stopOpacity="0" />
            <stop offset="100%" stopColor="currentColor" stopOpacity="0.5" />
          </linearGradient>
          <linearGradient id="spinner-firstHalf">
            <stop offset="0%" stopColor="currentColor" stopOpacity="1" />
            <stop offset="100%" stopColor="currentColor" stopOpacity="0.5" />
          </linearGradient>
        </defs>
        <g strokeWidth={logo ? '1' : '4'}>
          <path
            d="M 2 20 A 18 18 0 0 1 38 20"
            stroke="url(#spinner-secondHalf)"
          />
          <path
            d="M 38 20 A 18 18 0 0 1 2 20"
            stroke="url(#spinner-firstHalf)"
          />
          <path
            d="M 2 20 A 18 18 0 0 1 2 19.5"
            stroke="currentColor"
            strokeLinecap="round"
          />
        </g>
      </svg>
      {logo ? (
        <svg
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          color={color}
          height={size * 0.35}
          viewBox="0 0 40 28"
          width={size}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.0948 0.799988L24.5331 24.8332H16.6826L14.8206 14.491L12.4321 24.8332H5.04627L0.400002 7.74186L8.03709 5.95562L9.53422 16.1912L11.6646 5.10897L17.9044 3.6497L20.1965 16.3529L21.642 2.77895L30.0914 0.80343L30.0948 0.799988Z"
            fill="currentColor"
          />
          <path
            d="M39.7194 4.97821L36.0024 18.8791H30.0792L32.3317 1.1802L39.7228 4.97821H39.7194ZM35.5137 23.7801C35.5137 25.6386 34.0062 27.1426 32.1511 27.1426C30.2961 27.1426 28.7886 25.6352 28.7886 23.7801C28.7886 21.925 30.2961 20.4176 32.1511 20.4176C34.0062 20.4176 35.5137 21.925 35.5137 23.7801Z"
            fill="currentColor"
          />
        </svg>
      ) : null}
    </div>
  );
};
