import type { ToastContainerProps } from 'react-toastify';
import { ToastContainer, Slide } from 'react-toastify';

import { cn } from '@common/utils';

import styles from './ToastProvider.module.scss';

interface ToastProviderProps
  extends Omit<ToastContainerProps, 'transition' | 'draggable'> {}

export const ToastProvider = ({
  className,
  ...restProps
}: ToastProviderProps) => {
  return (
    <ToastContainer
      draggable
      stacked
      bodyClassName="font-400 text-Gray-white h-fit p-0"
      className={cn('p-0', className)}
      closeButton={false}
      draggableDirection="y"
      draggablePercent={20}
      position="bottom-center"
      theme="colored"
      toastClassName={`${styles['toast-wrap']} p-12 typo-body4 rounded-small`}
      transition={Slide}
      {...restProps}
    />
  );
};
