import { useEffect } from 'react';

import { getOsType, getSubDomain, isEnumValue } from '@common/utils';
import { useShallow } from 'zustand/react/shallow';

import { isKbWebview } from '@/modules/kb-pay';

import {
  SUB_DOMAIN,
  WEBVIEW_TYPE,
  useDeviceInfoStore,
} from './useDeviceInfoStore';

export const useMountDeviceInfoStore = () => {
  const [
    setIsMounted,
    setWebviewType,
    setOsType,
    setViewportType,
    setSubDomain,
  ] = useDeviceInfoStore(
    useShallow((state) => [
      state.setIsMounted,
      state.setWebviewType,
      state.setOsType,
      state.setViewportType,
      state.setSubDomain,
    ]),
  );

  useEffect(() => {
    const subDomain = getSubDomain();

    const { userAgent } = navigator;

    setOsType(getOsType());

    setViewportType(/Mobile/.exec(userAgent) ? 'mobile' : 'desktop');

    if (isEnumValue(SUB_DOMAIN, subDomain)) {
      setSubDomain(subDomain);

      switch (subDomain) {
        case SUB_DOMAIN.KB_PAY: {
          if (isKbWebview()) setWebviewType(WEBVIEW_TYPE.KB_PAY);

          break;
        }

        case SUB_DOMAIN.WELLO_APP: {
          if (userAgent.includes('welfarehello'))
            setWebviewType(WEBVIEW_TYPE.WELLO_APP);

          break;
        }

        default:
          break;
      }
    } else {
      setSubDomain(null);
    }

    setIsMounted(true);
  }, [setIsMounted, setOsType, setSubDomain, setViewportType, setWebviewType]);
};
